import http from "./http"
const baseUrl = 'https://pay.gxb.cn/api'
// 获取随机因子 token,flag_chnl:'PC'
export const $apiRandomPay = (data) => http({ method: "post", url:"/pay/pay/random", data, }) 
// 验证码申请 token
export const $apiCodeApply = (data) => http({ method: "post", url: baseUrl+"/verify/code/apply", data, }) 
// 验证码验证 token,code
export const $apiCodeVerify = (data) => http({ method: "post", url: baseUrl+"/verify/code/verify", data, }) 
// 开户申请 token,name姓名,id_no身份证号,id_exp证件有效期,id_emblem身份证正面,id_portrait身份证反面,linked_acctno银行卡号,linked_phone	银行卡预留手机号,
export const $apiApproveApply = (data) => http({ method: "post", url: baseUrl+"/person/open/acct/apply", data, }) 
// 开户验证 token,opne_code密码验证接口,verify_code银行预留手机短信验证码,password交易密码,txn_seqno,random_key
export const $apiApproveVerify = (data) => http({ method: "post", url: baseUrl+"/person/open/acct/verify", data, }) 
// 个人开户回调
export const $apiAcctCallBack = (data) => http({ method: "get", url: baseUrl+"/person/open/acct/call/back", data, }) 
// 认证状态查询 token
export const $apiAcctState = (data) => http({ method: "post", url: baseUrl+"/person/open/acct/state", data, }) 

