import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4dd95834"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["onClick"]

import { Plus, Close } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import { ref, watch } from 'vue'

// 关闭图片时触发得函数

export default {
  props: {
  limitNum:Number,//限制数量
  fileList:Array,//目前用于清空上传的图片
},
  emits: ['getFileList'],
  setup(__props, { emit: emits }) {

const props = __props

const closeHandler = file => {
  fileList.value = fileList.value.filter(v => v.url !== file.file.url)
  emits("getFileList",fileList.value)
}

// 切换图片
const handleAvatarChange = (file) => {
  return URL.createObjectURL(file.file.raw)
}


const fileList = ref([])

const change = (file, files) => {
  fileList.value = files
  emits("getFileList",fileList.value)
}

watch(()=>props.fileList,()=>{
  fileList.value = props.fileList
},{deep:true})

return (_ctx, _cache) => {
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_upload = _resolveComponent("el-upload")

  return (_openBlock(), _createBlock(_component_el_upload, {
    "file-list": fileList.value,
    "onUpdate:file-list": _cache[0] || (_cache[0] = $event => ((fileList).value = $event)),
    "list-type": "picture-card",
    "auto-upload": false,
    "on-change": change,
    "on-remove": change,
    multiple: __props.limitNum != 1,
    limit: __props.limitNum
  }, {
    file: _withCtx((file) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: handleAvatarChange(file),
          alt: ""
        }, null, 8, _hoisted_2),
        _createElementVNode("div", {
          class: "close",
          onClick: $event => (closeHandler(file))
        }, [
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode(_unref(Close))
            ]),
            _: 1
          })
        ], 8, _hoisted_3)
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_icon, null, {
        default: _withCtx(() => [
          _createVNode(_unref(Plus))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["file-list", "multiple", "limit"]))
}
}

}